import {useContext, useEffect} from 'react';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {PriceContext, initialPrice} from '../../context/PriceContext';
import {isInStudio} from '../../lib/plasmic-utils';
import ObserveEventReporter from '../ObserveEventReporter';
import {EMAIL_CTABLOCK_SEEN, CTABLOCK_SEEN} from '../../lib/analytics';

const dummyData = {
  currency: 'USD',
  annual_price: '$89.69',
  annual_monthly_price: '$7.47',
  monthly_price: '$14.95',
  coupon_valid: true,
  coupon_scope: 'all',
};

const dummyCoupon = {
  discounted_prices: {
    annual_price: '$44.84',
    annual_monthly_price: '$3.74',
    monthly_price: '$7.47',
  },
};

export default function CtaPrice({pullFakePrices, applyFakeCoupon, className}) {
  const router = useRouter();
  const {prices, setPrices} = useContext(PriceContext);

  // Effect only runs in plasmic studio.
  useEffect(() => {
    if (isInStudio(router.pathname)) {
      setPrices(initialPrice);

      if (pullFakePrices) {
        setPrices(prevPrices => ({...prevPrices, ...dummyData}));
      }

      if (applyFakeCoupon) {
        setPrices(prevPrices => ({...prevPrices, ...dummyCoupon}));
      }
    }
  }, [pullFakePrices, applyFakeCoupon]);

  const isCouponScope = scope => !!prices?.coupon_valid && prices?.coupon_scope === scope;
  if (isCouponScope('monthly')) {
    return (
      <Price
        className={className}
        priceWithDiscount={prices.discounted_prices.monthly_price}
        price={prices.monthly_price}
        period="mo"
      />
    );
  }
  if (isCouponScope('annual')) {
    return (
      <Price
        className={className}
        priceWithDiscount={prices.discounted_prices.annual_monthly_price}
        price={prices.annual_monthly_price}
        period="mo"
      />
    );
  }
  if (isCouponScope('all')) {
    return (
      <Price
        className={className}
        priceWithDiscount={prices.discounted_prices.annual_monthly_price}
        price={prices.annual_monthly_price}
        period="mo"
      />
    );
  }
  return <Price className={className} price={prices.annual_monthly_price} period="mo" />;
}

CtaPrice.propTypes = {
  className: PropTypes.string,
  pullFakePrices: PropTypes.bool,
  applyFakeCoupon: PropTypes.bool,
};
CtaPrice.defaultProps = {
  className: '',
  pullFakePrices: false,
  applyFakeCoupon: false,
};

export const PlasmicData = {
  name: 'Cta.Price',
  props: {
    pullFakePrices: {
      type: 'boolean',
      defaultValue: false,
      displayName: 'Dummy prices',
    },
    applyFakeCoupon: {
      type: 'boolean',
      defaultValue: false,
      displayName: 'Dummy coupon',
      hidden: props => !props.pullFakePrices,
    },
  },
  defaultStyles: {},
  importPath: './components/Subscription/CtaPrice',
  isDefaultExport: true,
};

export function Price({className, price, priceWithDiscount, period}) {
  return (
    <div className={className}>
      <ObserveEventReporter events={[EMAIL_CTABLOCK_SEEN, CTABLOCK_SEEN]} once />
      {priceWithDiscount && <p className="p-1 text-[0.5em] line-through opacity-50">{price}</p>}
      <p className="p-0.5">{priceWithDiscount || price}</p>
      <p className="p-0.5 text-[0.5em]">
        /{period}
        <sup>*</sup>
      </p>
    </div>
  );
}

Price.defaultProps = {
  priceWithDiscount: null,
};

Price.propTypes = {
  className: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  priceWithDiscount: PropTypes.string,
  period: PropTypes.oneOf(['yr', 'mo']).isRequired,
};
