import PropTypes from 'prop-types';
import {useRef} from 'react';
import {trackEvent} from '../lib/analytics';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

function ObserveEventReporter({events, customProperties, once}) {
  const sentinel = useRef(null);

  useIntersectionObserver(sentinel, {}, isIntersecting => {
    const isDispatched = JSON.parse(sentinel.current.dataset.dispatch);
    if (isIntersecting && isDispatched === false) {
      if (once) {
        sentinel.current.dataset.dispatch = true;
      }
      for (const eventName of events) {
        trackEvent(eventName, customProperties);
      }
    }
  });

  return <div ref={sentinel} data-dispatch={false} />;
}

export default ObserveEventReporter;

ObserveEventReporter.propTypes = {
  events: PropTypes.arrayOf(PropTypes.string).isRequired,
  customProperties: PropTypes.shape({}),
  once: PropTypes.bool,
};

ObserveEventReporter.defaultProps = {
  customProperties: {},
  once: false,
};

export const PlasmicData = {
  name: 'ObserveEventReporter',
  props: {
    eventName: {
      type: 'string',
      defaultValue: 'myTestEvent',
    },
    customProperties: 'object',
    once: 'boolean',
  },
  importPath: './components/ObserveEventReporter',
  isDefaultExport: true,
};
