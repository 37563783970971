import {useContext} from 'react';
import PropTypes from 'prop-types';
import {PriceContext} from '../../context/PriceContext';

export const PlasmicData = {
  name: 'Cta.Currency',
  props: {
    highlight: {
      type: 'string',
      defaultValue: '*Cancel anytime.',
    },
    beforeText: {
      type: 'string',
      defaultValue: 'Unlimited subscription billed yearly in ',
    },
    afterText: {
      type: 'string',
      defaultValue: '1 month minimum commitment if subscribers have downloaded any templates.',
    },
  },
  defaultStyles: {},
  importPath: './components/Subscription/CtaCurrency',
  isDefaultExport: true,
};

export default function CtaCurrency({className, highlight, beforeText, afterText}) {
  const {prices} = useContext(PriceContext);

  return (
    <p className={className} style={{display: 'inline'}}>
      <span className="font-semibold">{highlight}</span> {beforeText}
      {prices.currency}. {afterText}
    </p>
  );
}

CtaCurrency.propTypes = {
  className: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  afterText: PropTypes.string.isRequired,
  beforeText: PropTypes.string.isRequired,
};
