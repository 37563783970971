import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {UserContext} from '../../context/UserContext';
import ConditionalStack from '.';

export default function SubscriptionConditionalStack({children, toggle, ...props}) {
  const {currentUser} = useContext(UserContext);

  // if toggle true, show only if plan is null, else hide if there is an existing plan
  const condition = () => (toggle ? !currentUser?.plan : currentUser?.plan);

  return (
    <ConditionalStack condition={condition} {...props}>
      {children}
    </ConditionalStack>
  );
}

SubscriptionConditionalStack.defaultProps = {
  toggle: false,
};
SubscriptionConditionalStack.propTypes = {
  children: PropTypes.node.isRequired,
  toggle: PropTypes.bool,
};

export const SubscriptionConditionalStackPlasmicData = {
  name: 'Subscription Conditional Stack',
  props: {
    children: {
      type: 'slot',
      defaultValue: [
        {
          type: 'text',
          value:
            'Turn OFF will hide the content, once the subscription is correct then the content will be visible.',
        },
        {
          type: 'text',
          value:
            'Turn ON will show the content, once the subscription is correct then the content will hide.',
        },
      ],
    },
    toggle: {
      type: 'boolean',
      defaultValue: true,
      displayName: 'Visible',
      helpText:
        'Turn on will make the Slot: "children" visible for non subscribers. If it is turn off will be visible for subscribed users.',
    },
  },
  defaultStyles: {width: '100%', padding: '8px'},
  importPath: './components/ConditionalStack/SubscriptionConditionalStack',
  isDefaultExport: true,
};
