/**
 * Function to determine if `noFollowNoIndex` should be set to true
 * based on certain URL query parameters. This variable is later
 * used determining the addition of robots tag no index no follow
 *
 * @param {string} serverUrl - The server URL to check for specific query parameters.
 * @returns {boolean} - Returns true if the URL matches the specified conditions, otherwise false.
 */
const checkNoFollowNoIndex = serverUrl => {
  if (!serverUrl) return false;

  const url = new URL(serverUrl);
  const searchParams = new URLSearchParams(url.search);
  let fParamsCount = 0;

  // Check if the URL has a "search" parameter | Check 1
  if (searchParams.has('search')) {
    return true;
  }

  // Check if the "page" parameter is greater than 1 | Check 2
  const page = searchParams.get('page');
  if (page && parseInt(page, 10) > 1) {
    return true;
  }

  for (const [key, value] of searchParams.entries()) {
    if (key.startsWith('f_')) {
      fParamsCount++;
      // Check if the value of any "f_" parameter has 2 or more values | Check 3
      const values = value.split(',');
      if (values.length >= 2) {
        return true;
      }
    }
  }

  // If there are 3 or more "f_" params, set noFollowNoIndex to true | Check 4
  if (fParamsCount >= 3) {
    return true;
  }

  return false;
};

export default checkNoFollowNoIndex;
