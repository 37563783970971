import PropTypes from 'prop-types';

/**
 * Used for canonicals in dynamic SEO components.
 * Cleans the given URL by retaining only specific query parameters:
 * - Parameters that start with "f_"
 * - "search", "page", and "sortby" parameters
 * The returned canonical URL will always have the domain "https://placeit.net".
 *
 * @param {string} url - The URL to be cleaned
 * @returns {string} - The cleaned URL with only the specified parameters and "https://placeit.net" as the domain
 */
const cleanUrl = url => {
  if (!url) return url;

  const parsedUrl = new URL(url);
  const filteredParams = [...new URLSearchParams(parsedUrl.search)]
    .filter(
      ([key]) => key.startsWith('f_') || key === 'search' || key === 'page' || key === 'sortby'
    )
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const baseDomain = 'https://placeit.net';

  return `${baseDomain}${parsedUrl.pathname}${filteredParams ? `?${filteredParams}` : ''}`;
};

cleanUrl.propTypes = {
  url: PropTypes.string.isRequired,
};

export default cleanUrl;
