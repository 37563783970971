import {useEffect, useState, useCallback} from 'react';

/**
 * Custom hook to ease the use of the Intersection Observer API from a react component.
 *
 * @param {HTMLElement} target - The element to observe.
 * @param {IntersectionObserverInit} options - The options to pass to the Intersection Observer. See the Intersection Observer API for more info.
 * @param {CallbackFn} callback - The callback to call when the intersection changes.
 *
 * The CallbackFn has the following signature:
 * (boolean) => void
 *
 * @returns boolean - A boolean indicating if the target is intersecting.
 */

export default function useIntersectionObserver(target, options = {}, callback = undefined) {
  if (!target) {
    throw new Error('No target element provided');
  }

  const [isIntersecting, setIntersecting] = useState(false);

  const onIntersect = useCallback(([entry]) => {
    setIntersecting(entry.isIntersecting);
    if (callback) {
      callback(entry.isIntersecting);
    }
  });

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      ...options,
    });

    if (target.current) {
      observer.observe(target.current);
    }

    return () => {
      if (target.current) {
        observer.unobserve(target.current);
      } else {
        observer.disconnect();
      }
    };
  }, [target]);

  return isIntersecting;
}
